import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  dialog: {
    open: false,
    type: null,
    title: '',
    content: '',
    apiData: {},
    state: { needLogOut: false },
  },
  alertDialog: {
    open: false,
    title: '',
    text: '',
  },
  articleDialog: {
    open: false,
    categoryID: 0,
    articleID: 0,
    showPrevNextButton: true,
  },
}
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    modifyDialog: (state, action) => {
      state.dialog.open = action.payload.open
      state.dialog.type = action.payload.type
      state.dialog.title = action.payload.title
      state.dialog.content = action.payload.content
      state.dialog.apiData = action.payload.apiData
      state.dialog.state = action.payload.state
    },
    modifyAlertDialog: (state, action) => {
      state.alertDialog.open = action.payload.open
      state.alertDialog.title = action.payload.title
      state.alertDialog.text = action.payload.text
    },
    modifyArticleDialog: (state, action) => {
      state.articleDialog.open = action.payload.open
      state.articleDialog.categoryID = action.payload.categoryID
      state.articleDialog.articleID = action.payload.articleID
      state.articleDialog.showPrevNextButton = action.payload.showPrevNextButton
    },

  },
})

export const { modifyDialog, modifyAlertDialog, modifyArticleDialog } = systemSlice.actions
export default systemSlice.reducer
