import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import store from 'redux/store'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import history from './utils/history'

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
