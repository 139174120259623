import React from 'react'

const component = {
  home: React.lazy(() => import('../views/home')),

}
const routes = isMobile => [
  {
    path: '/',
    component: component.home,
    exact: true,
  },
]

export default routes
