import { configureStore } from '@reduxjs/toolkit'
import auth from '../auth/slice'
import system from '../system/slice'
import member from '../member/slice'

export default configureStore({
  reducer: {
    auth,
    system,
    member,
  },
})
