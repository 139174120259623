import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  // username: '',
  memberId: '',
  nickName: '',
  memberBalance: 0,
  memberLevel: 0,
  memberVIP: 0,
  vipValidTime: 0,
  activeValue: 0,
}
const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      // state.username = action.payload.username
      state.memberId = action.payload.memberId
      state.nickName = action.payload.nickName
      state.memberBalance = action.payload.memberBalance
      state.memberLevel = action.payload.memberLevel
      state.memberVIP = action.payload.memberVIP
      state.vipValidTime = action.payload.vipValidTime
      state.activeValue = action.payload.activeValue
    },
  },
})

export const { setInfo } = memberSlice.actions
export default memberSlice.reducer
