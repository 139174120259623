import React, { useCallback, Suspense, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import ScrollToTop from 'utils/scrollToTop'
import { StylesProvider } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router-dom'
import routes from 'router'
import AlertDialog from 'components/alertDialog/common'
import './app.sass'

function HandleRoutes(route) {
  const {
    path, exact, component,
  } = route

  const RenderRoutes = useCallback(() => (
    <Suspense fallback={<div style={{ height: '100vh' }}>Loading...</div>}>
      <Route
        path={path}
        exact={exact}
        component={component}
      />
    </Suspense>
  ), [component, exact, path])

  return (
    <RenderRoutes />
  )
}
function App() {
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`
    // script.addEventListener("load", handleLoaded);
    document.body.appendChild(script)
  }, [])
  window.onbeforeunload = () => {
    window.scrollTo(0, 0)
  }
  return (
    <StylesProvider injectFirst>
      <ScrollToTop />
      <CssBaseline />
      <Switch>
        {routes().map((route, i) => (
          <HandleRoutes
            key={route.path}
            {...route}
          />
        ))}
      </Switch>
      <AlertDialog />
    </StylesProvider>
  )
}

export default App
