import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { useSelector, useDispatch } from 'react-redux'
import { modifyAlertDialog } from 'redux/system/slice'

import styles from './alertDialog.module.sass'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)
function AlertDialog() {
  const dispatch = useDispatch()
  const { alertDialog } = useSelector(state => state.system)

  return (
    <Dialog
      TransitionComponent={Transition}
      className={styles.dialog}
      open={alertDialog.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={styles.title} id="alert-dialog-title">{alertDialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertDialog.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => { dispatch(modifyAlertDialog({ ...alertDialog, open: false })) }}>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AlertDialog
